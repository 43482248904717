import {Component} from '@angular/core';

@Component({
    selector: 'hashtag',
    templateUrl: './hashtag.component.html',
    styleUrls: ['./hashtag.component.css']
})
export class HashtagComponent {

}
