import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class ScrollService {

    private subject: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor() {
        document.addEventListener('scroll', () => {
            this.get().next(window.scrollY);
        });
    }

    public get(): BehaviorSubject<number> {
        return this.subject;
    }
}
