import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {WrapperComponent} from './wrapper/wrapper.component';
import {GuideComponent} from './guide/guide.component';
import {CenterComponent} from './center/center.component';
import {DeviceComponent} from './device/device.component';
import {SafePipe} from './device/safe.pipe';
import {ScrollService} from './_service/scroll.service';
import {HttpClientModule} from '@angular/common/http';
import {SettingsService} from './_service/settings.service';
import {HashtagComponent} from './hashtag/hashtag.component';
import {BlueprintComponent} from './blueprint/blueprint.component';
import {LoaderComponent} from './loader/loader.component';
import {NavigationComponent} from './navigation/navigation.component';

@NgModule({
    declarations: [
        AppComponent,
        BlueprintComponent,
        CenterComponent,
        DeviceComponent,
        GuideComponent,
        HashtagComponent,
        SafePipe,
        LoaderComponent,
        NavigationComponent,
        WrapperComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule
    ],
    providers: [
        ScrollService,
        SettingsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    public constructor(
        private settingsService: SettingsService,
        private titleService: Title,
        private metaService: Meta
    ) {
        this.settingsService.get('page/title').subscribe((title: string) => {
            this.titleService.setTitle(title);
        });
        this.settingsService.get('seo/keywords').subscribe((keywords: string) => {
            this.metaService.addTag({name: 'keywords', content: keywords});
        });
        this.settingsService.get('seo/description').subscribe((description: string) => {
            this.metaService.addTag({name: 'keywords', content: description});
        });
    }
}
