import {Component, Input} from '@angular/core';

@Component({
    selector: 'wrapper',
    templateUrl: './wrapper.component.html',
    styleUrls: ['./wrapper.component.css']
})
export class WrapperComponent {

    @Input()
    public id: string;

    public getAnchorName(): string {
        return this.id;
    }
}
