import {Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ScrollService} from '../_service/scroll.service';

@Component({
    selector: 'device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

    @Input()
    public url: string;

    private isInitialized = false;

    constructor(public scrollService: ScrollService, public elRef: ElementRef) {
    }

    public ngOnInit(): void {
        this.scrollService.get().subscribe(() => {
            if (!this.isInitialized) {
                const rect = this.elRef.nativeElement.getBoundingClientRect();
                if (rect.bottom > 0 && rect.top < (window.innerHeight || document.documentElement.clientHeight)) {
                    this.isInitialized = true;
                }
            }
        });
    }

    public getUrl(demo: boolean = false): string {
        if(demo) {
            return this.url + '#demo';
        }
        return this.url;
    }

    public isInView(): boolean {
        return this.isInitialized;
    }
}
