import {Component} from '@angular/core';

@Component({
    selector: 'blueprint',
    templateUrl: './blueprint.component.html',
    styleUrls: ['./blueprint.component.css']
})
export class BlueprintComponent {

}
