import {Component, Input} from '@angular/core';

@Component({
    selector: 'guide',
    templateUrl: './guide.component.html',
    styleUrls: ['./guide.component.css']
})
export class GuideComponent {

    @Input()
    public to: string;

    @Input()
    public direction = 'down';

    public getLink(): string {
        return '#' + this.to;
    }
}
